const supportedOriginCountries = {
  US: {
    alpha2: 'US',
    alpha3: 'USA',
    countryCallingCodes: ['+1'],
    currencies: ['USD'],
    emoji: '🇺🇸',
    ioc: 'USA',
    languages: ['eng'],
    name: 'United States',
    defaultWarehouseCode: 'US-DEL-CAM',
    flagIconUrl: '/svg/flags/icon-flag-us.svg',
  },
  GB: {
    alpha2: 'GB',
    alpha3: 'GBR',
    countryCallingCodes: ['+44'],
    currencies: ['GBP'],
    emoji: '🇬🇧',
    ioc: 'GB',
    languages: ['eng'],
    name: 'United Kingdom',
    defaultWarehouseCode: 'EU-UK-MAN',
    flagIconUrl: '/svg/flags/icon-flag-us.svg',
  },
  CN: {
    alpha2: 'CN',
    alpha3: 'CNH',
    countryCallingCodes: ['+86'],
    currencies: ['CNY'],
    emoji: '🇨🇳',
    ioc: 'CN',
    languages: ['chn'],
    name: 'China',
    defaultWarehouseCode: 'CN-GD-CAN',
    flagIconUrl: '/svg/flags/icon-flag-cn.svg',
  },
};

export default supportedOriginCountries;
